<div class="footer-banner">
  <div class="px-5 py-5 pb-3">
    <div class="row">
      <div class="col-lg-3 col-sm-6 mb-3">
        <h5 class="footer-title"> {{
          (CommonContent && CommonContent.businessJobHeading) ?? "Business & Jobs"}}</h5>
        <ul class="footer-item list-unstyled">
          <li>  {{(CommonContent && CommonContent.businessAddress) ?? "6053 W. Century Blvd., Suite 300 Los Angeles, CA 90045"}}</li>
          <li class="">
            <!-- <img src="../../../../assets/icons/fi_phone.svg" alt="Contact Phone Number" /> -->
            <svg class="me-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.9999 16.9201V19.9201C22.0011 20.1986 21.944 20.4743 21.8324 20.7294C21.7209 20.9846 21.5572 21.2137 21.352 21.402C21.1468 21.5902 20.9045 21.7336 20.6407 21.8228C20.3769 21.912 20.0973 21.9452 19.8199 21.9201C16.7428 21.5857 13.7869 20.5342 11.1899 18.8501C8.77376 17.3148 6.72527 15.2663 5.18993 12.8501C3.49991 10.2413 2.44818 7.27109 2.11993 4.1801C2.09494 3.90356 2.12781 3.62486 2.21643 3.36172C2.30506 3.09859 2.4475 2.85679 2.6347 2.65172C2.82189 2.44665 3.04974 2.28281 3.30372 2.17062C3.55771 2.05843 3.83227 2.00036 4.10993 2.0001H7.10993C7.59524 1.99532 8.06572 2.16718 8.43369 2.48363C8.80166 2.80008 9.04201 3.23954 9.10993 3.7201C9.23656 4.68016 9.47138 5.62282 9.80993 6.5301C9.94448 6.88802 9.9736 7.27701 9.89384 7.65098C9.81408 8.02494 9.6288 8.36821 9.35993 8.6401L8.08993 9.9101C9.51349 12.4136 11.5864 14.4865 14.0899 15.9101L15.3599 14.6401C15.6318 14.3712 15.9751 14.1859 16.3491 14.1062C16.723 14.0264 17.112 14.0556 17.4699 14.1901C18.3772 14.5286 19.3199 14.7635 20.2799 14.8901C20.7657 14.9586 21.2093 15.2033 21.5265 15.5776C21.8436 15.9519 22.0121 16.4297 21.9999 16.9201Z" stroke="#F0F0F0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            {{(CommonContent && CommonContent.businessPhone) ?? "(424) 646-7300"}}</li>
          <li>
            <a href="mailto:{{ CommonContent && CommonContent.businessEmail ? CommonContent.businessEmail : 'businessandjobs@lawa.org' }}">
              <!-- <img src="../../../../assets/icons/fi_mail.svg" alt="Send an email" /> -->
              <svg class="me-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z" stroke="#F0F0F0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M22 6L12 13L2 6" stroke="#F0F0F0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              {{ CommonContent && CommonContent.businessEmail ? CommonContent.businessEmail : 'businessandjobs@lawa.org' }}
            </a>
          </li>
          <li class="">
            <!-- <img src="../../../../assets/icons/fi_clock.svg" alt="Indicating time information"/>  -->
            <svg class="me-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#F0F0F0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 6V12L16 14" stroke="#F0F0F0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          {{(CommonContent && CommonContent.businessTime) ?? "Monday - Friday 8am-6pm"}}</li>
        </ul>
      </div>
      <div class="col-lg-3 col-sm-6 mb-3">
        <h5 class="footer-title">Support</h5>
        <ul class="footer-item list-unstyled">
          <li><a href="{{ CommonContent && CommonContent.cityOfLa }}">City of LA</a></li>
          <li><a href="{{ CommonContent && CommonContent.accessibility }}">Accessibility</a></li>
          <li><a href="{{ CommonContent && CommonContent.commentsContactUs }}">Comments/Contact Us</a></li>
        </ul>
      </div>
      <div class="col-lg-3 col-sm-6 mb-3">
        <h5 class="footer-title">Legal & Information</h5>
        <ul class="footer-item list-unstyled">
          <li><a href="{{ CommonContent && CommonContent.disclaimer }}">Disclaimer</a></li>
          <li><a href="{{ CommonContent && CommonContent.privacyPolicy }}">Privacy Policy</a></li>
          <li><a href="{{ CommonContent && CommonContent.sitemap }}">Sitemap</a></li>
        </ul>
      </div>
      <div class="col-lg-3 col-sm-6 mb-3">
        <h5 class="footer-title">{{(CommonContent && CommonContent.getInTouchHeading) ?? "Get In Touch"}}</h5>
        <ul class="footer-item list-unstyled">
          <li class="white-space-prewrap" *ngIf="(CommonContent && CommonContent.getInTouchAddress)">
            <span [innerHTML]="CommonContent.getInTouchAddress"></span>
          </li>
          <li *ngIf="!(CommonContent && CommonContent.getInTouchAddress)">
            <span>
              Los Angeles International Airport, <br /> 1 World Way, Los Angeles, CA 90045
            </span>
          </li>
          <li>{{(CommonContent && CommonContent.getInTouchPhone) ?? "Ph: (855) 463-5252"}}</li>
          <li *ngIf="(CommonContent && CommonContent.getInTouchMessage)">
            <span [innerHTML]="CommonContent.getInTouchMessage"></span>
          </li>
          <li *ngIf="!(CommonContent && CommonContent.getInTouchMessage)">
            <span>
              For TTY, please call California Relay Service at (800) 735-2929
            </span>
          </li>
        </ul>
      </div>
      <div class="col-md-7 mb-3">
        <h5 class="footer-title"> {{
          (CommonContent && CommonContent.fshpHeading) ?? "First Source Hiring Program"}} </h5>
        <p class="footer-p">{{
          (CommonContent && CommonContent.fshpText) ?? "The First Source Hiring Program (FSHP) is a workforce development program managed  by the Business, Jobs and Social Responsibility Division at Los Angeles World Airports.  FSHP connects job seekers in the  Los Angeles area to quality airport careers. The FSHP also connects job seekers with career readiness resources and  helps the companies doing business at LAWA find the talent needed to operate a world-class, gold standard airport."}}</p>
      </div>
      <div class="col-md-5 mb-3">
        <div class="d-flex justify-content-start align-items-baseline flex-wrap">
          <span class="popular-job-heading pt-2">Popular Job Categories:</span>
          <ng-container *ngIf="CommonContent">
            <span *ngFor="let item of CommonContent.popularJobs" class="popular-jobs">{{item}}</span>
          </ng-container>

        </div>
      </div>
    </div>
    <hr class="text-white" />
    <div class="d-flex justify-content-between align-items-center">
      <p class="copyright-text mb-0">LAWA © 2024. All rights reserved.</p>
      <div class="d-flex gap-3">
        <p class="copyright-text mb-0">LAX</p>
        <p class="copyright-text mb-0">VNY</p>
      </div>
    </div>
  </div>

</div>
