import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service'; // Assume you have an AuthService for authentication checks
import { AppConstants } from 'src/app/appconstants';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    let userInfo = localStorage.getItem(AppConstants.USER);
    if (this.authService.isAuthenticated && userInfo && userInfo != '') {
      try {
        const user = JSON.parse(userInfo);
        if (
          route.data['role'] &&
          route.data['role'].indexOf(user.role) === -1
        ) {
          return this.reroute('unauthorized');
        }
      } catch {
        return this.reroute('unauthorized');
      }
      return true;
    } else {
      return this.reroute('auth/login');
    }
  }
  private reroute(path:string): boolean {
    this.router.navigate([`/${path}`]);
    return false;
  }
}
