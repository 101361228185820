import { Injectable, NgZone } from '@angular/core';
import {
  fromEvent,
  interval,
  merge,
  Subscription,
  takeUntil,
  timer,
} from 'rxjs';
import { AuthService } from './authguard/auth.service';

@Injectable({
  providedIn: 'root',
})
export class IdleTimeoutService {
  private idleSubscription: any = Subscription;
  private lastUpdateTime = new Date();

  constructor(private _zone: NgZone, private _authService: AuthService) {}

  startWatching() {
    this.subscribeEvents();
    this.initialize();
  }

  stopWatching() {
    if (this.idleSubscription && !this.idleSubscription.EMPTY?.closed) {
      this.idleSubscription.unsubscribe();
    }
  }

  private subscribeEvents() {
    const mouseMove$ = fromEvent(document, 'mousemove');
    const keyDown$ = fromEvent(document, 'keydown');
    const click$ = fromEvent(document, 'click');
    const activity$ = merge(mouseMove$, keyDown$, click$);
    this.idleSubscription = activity$.subscribe(() => {
      this.logoutInterval();
      this.lastUpdateTime = new Date();
    });
  }

  private initialize(): void {
    this.lastUpdateTime = new Date();
    this._zone.runOutsideAngular(() => {
      setInterval(() => {
        this.logoutInterval();
      }, 1000);
    });
  }

  private logoutInterval(): void {
    const minutes = this.getMinutes();
    if (minutes >= 20) {
      this._authService.logout();
    }
  }

  private getMinutes(): number {
    const diffInMilliseconds = Math.abs(
      new Date().getTime() - this.lastUpdateTime.getTime()
    );
    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    return diffInMinutes;
  }
}
