<ng-container *ngIf="loginDisplay; else loggedOutTemplate">
  <div>
    <div class="dropdown">
      <button
        class="btn dropdown-toggle"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img
           [src]="previewUserImage ? previewUserImage : '../../../../assets/icons/user-profile.svg'"
          alt="Logged User Profile Image"
          class="rounded-circle object-fit-cover"
          width="40px"
          height="40px"
        />
        <span
          class="profileName"
          >{{activeAccount ? activeAccount.idTokenClaims?.['given_name'] : 'Unknown'}}</span
        >
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <div class="card border-0 p-0" style="width: 18rem">
          <div class="card-body p-0">
            <div class="row m-0 mt-3 p-0 align-items-center">
              <div class="col-3 m-0 p-0 text-center">
                <img
                  [src]="previewUserImage"
                  alt="John Doe"
                  class="rounded-circle me-3"
                  width="40px"
                  hight="40px"
                />
              </div>
              <div class="col-9 m-0 p-0">
                <h5 class="card-title m-0">
                  {{activeAccount ? activeAccount.idTokenClaims?.['given_name'] : 'Unknown'}}
                </h5>
                <h6 class="card-subtitle mb-2 text-muted m-0">
                  {{email}}
                </h6>
              </div>
            </div>
            <div class="px-4 pt-2 pb-2"></div>
            <div class="list-group m-0">
              <a
              *ngIf="IsCurrentUserLawaStaffMember()"
              class="list-group-item border-0 list-group-item-action"
              (click)="OpenImpersonatePopup()">  Impersonation  </a>
              <a
                class="list-group-item border-0 list-group-item-action"
                (click)="viewProfile()"
                >Profile</a
              >
              <a
                href="#"
                class="list-group-item border-0 list-group-item-action"
                (click)="logout()"
                *ngIf="!isImpersonating"
                >Logout</a
              >
              <a
              *ngIf="isImpersonating"
              class="list-group-item border-0 list-group-item-action"
              (click)="logoutOfImpersonation()">  Logout of impersonation  </a>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loggedOutTemplate>
  <div>
    <button class="btn btn-lg btnLogin" (click)="onLogin()">
      Login / Register
    </button>
  </div>
</ng-template>
