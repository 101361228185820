import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  PopupRequest,
  RedirectRequest,
  SsoSilentRequest,
  PublicClientApplication,
} from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { IdTokenClaims, PromptValue } from '@azure/msal-common';
import { b2cPolicies, SignUpRoles } from '@portal/configs';
import { DatePipe } from '@angular/common';
import { ContentService } from '@portal/services';
import { AuthService } from './services/authguard/auth.service';
import { UserInfoService } from './services/userclaims/userinfo.service';
import { AppConstants } from './appconstants';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ProfileService } from './services/profile.service';
import { AppMonitorService } from './services/app-monitor.service';
import { TranslationService } from './services/translation.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { IdleTimeoutService } from './services/idle-timeout.service';
import { LoadingService } from './services/loading.service';
const b2cConfigs = environment.b2cConfigs;

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string;
  tfp?: string;
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  providers: [DatePipe],
})
export class AppComponent implements OnInit, OnDestroy {
  commonContent: any;
  isLoading: boolean = false;
  currentRoute:string=''
  showComponents:boolean = true;
  private hasSetUser: boolean = false;
  private readonly _destroying$ = new Subject<void>();
  constructor(
    private toastr: ToastrService,
    private profileService: ProfileService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private readonly contentService: ContentService,
    private readonly auth: AuthService,
    private userInfoService: UserInfoService,
    private router: Router,
    private loadingService: LoadingService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private appMonitorService: AppMonitorService,
    private translationService: TranslationService,
    private idleTimeoutService: IdleTimeoutService,
    private route:Router
  ) {}
  authenticated: boolean = false;
  ngOnInit(): void {
    this.loadingService.loading$.subscribe((loading) => {
      this.isLoading = loading;
    });
    this.checkRoute()
    this.subscribeEvents();
    this.getCommonContent();
    this.passwordUpdated();
    this.InvalidUserMessage();
    this.initializeIdleTimeout();
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        let payload = result.payload as AuthenticationResult;
        let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;
        this.authService.instance.setActiveAccount(payload.account);

        if (
          idtoken.acr === b2cPolicies.names.resetPassword ||
          idtoken.tfp === b2cPolicies.names.resetPassword
        ) {
          localStorage.setItem('passwordUpdated', 'updated');
          this.auth.logout();
        }

        if (!localStorage.getItem('Access-Token-Azure-B2C')) {
          const userId = payload?.uniqueId;
          this.appMonitorService.setUserContext(
            userId,
            payload?.account?.username
          );

          const user = {
            userId: '',
            firstName: '',
            lastName: '',
            email: payload?.account?.username,
            role: this.getRole(idtoken),
            profileCompletionStatus: ''
          };
          localStorage.setItem(AppConstants.USER, JSON.stringify(user));
          this.authenticated = true;
          localStorage.setItem(AppConstants.LOGINSTATUS, AppConstants.TRUE);
          //this.auth.loggedIn.next(true);
        }
        if (!this.hasSetUser) {
          this.setUser();
          this.hasSetUser = true;
        }
        this.initializeIdleTimeout();
        localStorage.setItem('Access-Token-Azure-B2C', payload.idToken);

        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
         * from SUSI flow. "acr" claim in the id token tells us the policy (NOTE: newer policies may use the "tfp" claim instead).
         * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (
          idtoken.acr === b2cPolicies.names.editProfile ||
          idtoken.tfp === b2cPolicies.names.editProfile
        ) {
          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = this.authService.instance
            .getAllAccounts()
            .find(
              (account: AccountInfo) =>
                account.idTokenClaims?.oid === idtoken.oid &&
                account.idTokenClaims?.sub === idtoken.sub &&
                ((account.idTokenClaims as IdTokenClaimsWithPolicyId).acr ===
                  b2cPolicies.names.signUpSignIn ||
                  (account.idTokenClaims as IdTokenClaimsWithPolicyId).tfp ===
                    b2cPolicies.names.signUpSignIn)
            );

          let signUpSignInFlowRequest: SsoSilentRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            account: originalSignInAccount,
          };

          // silently login again with the signUpSignIn policy
          this.authService.ssoSilent(signUpSignInFlowRequest);
        }

        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow (see above ln. 74-92).
         */
        // if (
        //   idtoken.acr === b2cPolicies.names.resetPassword ||
        //   idtoken.tfp === b2cPolicies.names.resetPassword
        // ) {
        //   this.logout();
        //   // this.login(signUpSignInFlowRequest);
        // }
      });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const languageCode = sessionStorage.getItem('languageselected') || 'EN';
        setTimeout(() => {
          this.translationService.updateLanguage(languageCode);
        }, 500);
      });
  }

  private subscribeEvents() {
    this.profileService.signUpEvent
      .pipe(takeUntil(this._destroying$))
      .subscribe((value) => {
        if (value) {
          this.authTrigger(null, value);
        }
      });
  }

  private initializeIdleTimeout(): void {
    if (this.auth.isAuthenticated) {
      this.idleTimeoutService.startWatching();
    }
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest): void {
    // if (userFlowRequest) {
    //   this.auth.login(userFlowRequest);
    // } else {
    //   this.auth.login();
    // }

    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
        ...userFlowRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect(userFlowRequest);
    }
  }

  private invalidUser(status: any) {
    localStorage.setItem('userStatus', status);
    this.auth.logout();
  }

  private InvalidUserMessage() {
    var res = localStorage.getItem('userStatus');
    if (res && res === 'notfound') {
      alert(
        'User not assigned to access this application, Contact administrator'
      );
      localStorage.removeItem('userStatus');
    }
  }

  private async loadUserProfile(): Promise<void> {
    this.loadingService.show();
    let user = this.profileService.getUserClaims();
    if (!user) return;
    if (user.userId) return;
    if (user.role === '') {
      this.auth.getUerProfile().subscribe((response: any) => {
        const userData = response.data;
        if (!userData || userData == null) {
          return this.invalidUser('notfound');
        }

        if (userData.userProfile == null) {
          userData.userProfile = {
            id: userData.cboProfile?.id,
            firstName: userData.cboProfile?.repFirstName,
            lastName: userData.cboProfile?.repLastName,
            isActive: userData.cboProfile?.isActive,
          };
        }

        user = {
          userId: userData.userProfile.id,
          firstName: userData.userProfile.firstName,
          lastName: userData.userProfile.lastName,
          employer: userData.employer?.id || null,
          role: userData.role,
          isApproved:
            userData.employer == null
              ? userData.cboProfile?.isApproved
              : userData.employer?.isApproved,
          email: user.email == null ? userData.cboProfile.repEmail : user.email,
          profileCompletionStatus: userData.userProfile.profileCompletionStatus,
        };

        let userName = userData.userProfile.firstName + ' ' + userData.userProfile.lastName;
        this.profileService.updateUserName(userName);
        
        // Store the basic user details in local storage
        localStorage.setItem(AppConstants.USER, JSON.stringify(user));
        if (response.data.employer != undefined) {
          const employer = {
            employerId: response.data.employer.id,
            employerName: response.data.employer.name,
            einNumber: response.data.employer.einNumber,
          };
          localStorage.setItem(
            AppConstants.EMPLOYERDATA,
            JSON.stringify(employer)
          );
        }

        this.auth.loggedIn.next(true);

        this.checkUserStatus(userData);
        const isJobSeeker = userData.role === AppConstants.JOBSEEKER;
        const isEmployer = userData.role === AppConstants.EMPLOYER;
        const isStaff = userData.role === AppConstants.LAWASTAFF;
        const isCBO = userData.role === AppConstants.COMMPARTNER;

            if (isJobSeeker) {
            if (userData.userProfile.firstName) {
              this.router.navigate([AppConstants.JOBSEEKER_DASHBOARD]);
            } else {
              this.gotoProfile(user);
            }
          } else if (isEmployer) {
            if (userData.employer?.isApproved) {
              this.router.navigate([AppConstants.EMPLOYER_DASHBOARD]);
            } else if (userData.employer?.name) {
              this.router.navigate([AppConstants.EMPLOYER_PROFILE_URL]);
            } else {
              this.gotoProfile(user);
            }
          } else if (isStaff) {
            this.router.navigate([AppConstants.LAWASTAFF_DASHBAORD]);
          } else if (isCBO) {
            if (userData.cboProfile?.isApproved) {
              this.router.navigate([AppConstants.CBO_PROFILE]);
            } else {
              this.gotoProfile(user);
            }
          }
          this.loadingService.hide();
        })
    }

    // else {
    //   this.gotoProfile(user);
    // }
  }

  checkUserStatus(user: any): void {
    if (!user.userProfile.isActive) {
      this.profileService.enableDisableUser(user.userProfile.id).subscribe({
        next: (resp) => {
          if (resp.data) {
            this.toastr.success(AppConstants.REACTIVATED, '', {
              timeOut: 60000,
              closeButton: true,
            });
          }
        },
        error: (err) => {
          console.error('Error enabling user', err);
        },
      });
    }
  }

  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  getCommonContent(): void {
    this.contentService.getContentItemByPath20('/').subscribe({
      next: (resp: any) => {
        this.commonContent = resp.properties;
      },
      complete: () => {},
      error: (error) => {
        console.log(error);
      },
    });
  }

  private getRole(obj: any): string {
    let role = '';
    if (obj.tfp == environment.b2cConfigs.policyNames.signupEmployer)
      role = SignUpRoles.Employer;
    else if (obj.tfp == environment.b2cConfigs.policyNames.signupJobseeker)
      role = SignUpRoles.JobSeeker;
    else if (obj.tfp == environment.b2cConfigs.policyNames.signupCBO)
      role = SignUpRoles.CBO;
    return role;
  }

  private authTrigger(userFlowRequest: any, type: any): void {
    if (type) {
      let authority: string | undefined;
      const b2cPolicies = {
        authorities: {
          signUpSignIn: {
            authority: `https://${b2cConfigs.tenantName}.b2clogin.com/${b2cConfigs.tenantName}.onmicrosoft.com/${b2cConfigs.policyNames.signUpSignIn}`,
          },
          editProfile: {
            authority: `https://${b2cConfigs.tenantName}.b2clogin.com/${b2cConfigs.tenantName}.onmicrosoft.com/${b2cConfigs.policyNames.editProfile}`,
          },
          resetPassword: {
            authority: `https://${b2cConfigs.tenantName}.b2clogin.com/${b2cConfigs.tenantName}.onmicrosoft.com/${b2cConfigs.policyNames.resetPassword}`,
          },
          signupEmployer: {
            authority: `https://${b2cConfigs.tenantName}.b2clogin.com/${b2cConfigs.tenantName}.onmicrosoft.com/${b2cConfigs.policyNames.signupEmployer}`,
          },
          signupJobseeker: {
            authority: `https://${b2cConfigs.tenantName}.b2clogin.com/${b2cConfigs.tenantName}.onmicrosoft.com/${b2cConfigs.policyNames.signupJobseeker}`,
          },
          signupCBO: {
            authority: `https://${b2cConfigs.tenantName}.b2clogin.com/${b2cConfigs.tenantName}.onmicrosoft.com/${b2cConfigs.policyNames.signupCBO}`,
          },
        },
        authorityDomain: `${b2cConfigs.tenantName}.b2clogin.com`,
      };
      switch (type) {
        case 'jobseeker':
          authority = b2cPolicies.authorities.signupJobseeker.authority;
          break;
        case 'employer':
          authority = b2cPolicies.authorities.signupEmployer.authority;
          break;
        case 'login':
          authority = b2cPolicies.authorities.signUpSignIn.authority;
          break;
        case 'reset':
          authority = b2cPolicies.authorities.resetPassword.authority;
          break;
        case 'cbo':
          authority = b2cPolicies.authorities.signupCBO.authority;
          break;
      }

      let editProfileFlowRequest: RedirectRequest | PopupRequest = {
        authority: authority,
        scopes: [],
      };

      return this.login(editProfileFlowRequest);

      if (localStorage.getItem('Access-Token-Azure-B2C') == null) {
        this.authService.instance = new PublicClientApplication({
          auth: {
            clientId: b2cConfigs.clientId,
            authority: authority,
            knownAuthorities: [b2cPolicies.authorityDomain],
            redirectUri: b2cConfigs.redirectUri,
            postLogoutRedirectUri: b2cConfigs.logoutRedirectUri,
          },
        });

        this.authService.initialize().subscribe((ev: any) => {
          this.authService
            .handleRedirectObservable()
            .subscribe((response: any) => {
              if (this.msalGuardConfig.authRequest) {
                this.authService.loginRedirect({
                  ...this.msalGuardConfig.authRequest,
                  ...userFlowRequest,
                } as RedirectRequest);
              } else {
                this.authService.loginRedirect(userFlowRequest);
              }
            });
        });
      }
    }
  }

  private gotoProfile(obj: any): void {
    if (obj.role === SignUpRoles.Employer) {
      this.router.navigate([AppConstants.EMPLOYER_EDIT_PROFILE]);
    } else if (obj.role === SignUpRoles.JobSeeker) {
      this.router.navigate([AppConstants.JOBSEEKER_EDIT_PROFILE]);
    } else if (obj.role === SignUpRoles.CBO) {
      this.router.navigate([AppConstants.CBO_PROFILE_EDIT]);
    } else if (obj.role === SignUpRoles.Staff) {
      this.router.navigate([AppConstants.LAWASTAFF_DASHBAORD]);
    } else {
      this.router.navigate(['/']);
    }
  }

  private async setUser(): Promise<void> {
    let user = this.profileService.getUserClaims();
    if (user.userId || !user.role) {
      this.loadUserProfile();
      return;
    }
    await this.userInfoService
      .createProfile({
        email: user.email,
        role: user.role,
      })
      .subscribe((res: any) => {
        if (res.success) {
          localStorage.setItem(
            AppConstants.USER,
            JSON.stringify({
              userId: res.data,
              firstName: user.firstName,
              lastName: user.lastName,
              role: user.role,
              email: user.email,
              isApproved: false,
            })
          );
          this.gotoProfile({
            userId: res.data,
            firstName: user.firstName,
            lastName: user.lastName,
            role: user.role,
            email: user.email,
          });
          this.auth.loggedIn.next(true);
        }
      });
  }

  private passwordUpdated() {
    let key = localStorage.getItem('passwordUpdated');
    if (key) {
      setTimeout(() => {
        this.toastr['success'](
          'Your password has been changed successfully!',
          'Password Changed'
        );
        localStorage.removeItem('passwordUpdated');
      }, 10);
    }
  }
  private checkRoute(){
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.currentRoute = this.router.url;
        if(this.currentRoute.includes('employer/ein-verify')){
          this.showComponents = false;
        }
        else{
          this.showComponents = true;
        }
      });
  }
}
