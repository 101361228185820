import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
} from '@azure/msal-browser';
import {
  MsalGuard,
  MsalBroadcastService,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MsalGuardConfiguration,
  MsalRedirectComponent,
  MsalModule,
  MsalInterceptor,
  MSAL_INTERCEPTOR_CONFIG,
  MsalInterceptorConfiguration,
} from '@azure/msal-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Configuration, loginRequest, msalConfig } from './configs';
import { UserLoginComponent } from './components/user-login/user-login.component';
import { ContentService, UmbracoServiceModule } from '@portal/services';
import { UserInfoService } from './services/userclaims/userinfo.service';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './shared/shared.module';
import { AdminService } from './services/admin/admin.service';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { AppHttpInterceptor } from './services/authguard/auth-interceptor';
import { MatMenuModule } from '@angular/material/menu';
import { JobDetailsModalComponent } from './components/job-details-modal/job-details-modal.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { WorksourceCenterComponent } from './features/employer/worksource-center/worksource-center.component';
import { environment } from 'src/environments/environment';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { LoaderComponent } from './components/loader/loader.component';
import { ConfigurationService } from './services/configuration.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { AppMonitorService } from './services/app-monitor.service';
import { ErrorHandlerService } from './services/error-handler.service';
/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

// MSAL Interceptor Configuration Factory
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiUrl, [
    `https://${environment.b2cConfigs.tenantName}.onmicrosoft.com/api/access_as_user`,
  ]);

  return {
    interactionType: InteractionType.Redirect, // or InteractionType.Popup
    protectedResourceMap,
  };
}

export function initConfig(configService: ConfigurationService) {
  return () => configService.refreshAccessToken();
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    FooterComponent,
    UserLoginComponent,
    UnauthorizedComponent,
    JobDetailsModalComponent,
    WorksourceCenterComponent,
    LoaderComponent,
  ],
  imports: [
    HttpClientModule,
    MsalModule.forRoot(
      MSALInstanceFactory(),
      MSALGuardConfigFactory(),
      MSALInterceptorConfigFactory()
    ),
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    UmbracoServiceModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right', // You can customize this
      timeOut: 3000, // Duration in milliseconds
      preventDuplicates: true,
    }),
    NgxGoogleAnalyticsModule.forRoot(environment.googleTag),
  ],
  providers: [
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [ConfigurationService],
      multi: true,
    },

    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },

    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },

    //{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    ContentService,
    UserInfoService,
    AdminService,
    provideAnimationsAsync(),
    AppMonitorService,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
  public static forRoot(
    configurationFactory: () => Configuration
  ): ModuleWithProviders<AppModule> {
    return {
      ngModule: AppModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }],
    };
  }
}
