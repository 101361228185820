import { ErrorHandler, Injectable } from '@angular/core';
import { AppMonitorService } from './app-monitor.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService extends ErrorHandler {
  constructor(private myMonitoringService: AppMonitorService) {
    super();
  }

  override handleError(error: Error) {
    this.myMonitoringService.logException(error); // Manually log exception
  }
}
